:root {
  --primary-color: #cd6688;
  --primary-color-dark: #cd6688;
  --primary-color-success: #388e3c;
  --text-highlight: #cd000b;
  --bg-color: #e5e5e5;
}

* {
  font-family: "Lexend", sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background: #e5e5e5;
  font-family: "Lexend", sans-serif !important;
  margin: 0;
}

a {
  text-decoration: none;
}

header {
  z-index: 10 !important;
}
main {
  background: #e5e5e5;
}

span,
label,
p {
  font-weight: 500;
}

.pageProgress {
  height: 3px;
  width: 80%;
  background: #c27b00;
  position: absolute;
  top: 0;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}
.ant-card-body {
  width: 100%;
  height: 100%;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.logo {
  height: 36px;
  user-select: none;
  object-fit: contain;
  object-position: center;
}

.flex {
  display: flex;
}

.flexRow {
  flex-direction: row;
}
.flexColumn {
  flex-direction: column;
}

.ant-picker-dropdown,
.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e5deeb !important;
}

.ant-select-item-option-selected {
  background: #e5deeb !important;
}

.calculationDescription .ant-descriptions-item-label {
  width: 300px;
}

.alignCenter,
.align-center {
  align-items: center;
}

.justifyCenter,
.justify-center {
  justify-content: center;
}

/* Margins */
.mt05 {
  margin-top: 0.5rem !important;
}
.mt1 {
  margin-top: 1rem;
}
.mt15 {
  margin-top: 1.5rem;
}
.mt2 {
  margin-top: 2rem;
}
.mb05 {
  margin-bottom: 0.5rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.mb15 {
  margin-bottom: 1.5rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mb275 {
  margin: 0.275rem 0;
}
.mt275 {
  margin-top: 0.275rem;
}
.mt75 {
  margin-top: 0.75rem;
}
.mr275 {
  margin-right: 0.275rem;
}
.mr1 {
  margin-right: 1rem;
}
.mr05 {
  margin-right: 0.5rem;
}
.ml05 {
  margin-left: 0.5rem;
}
/* Padding */
.p05 {
  padding: 0.5rem !important;
}
.p1 {
  padding: 1rem;
}
.p15 {
  padding: 1.5rem !important;
}
.p2 {
  padding: 2rem !important;
}
/* .ant-btn-primary {
  color: #222;
  background-color: #edd48f;
  border-color: #edd48f;
}
.ant-menu-item-selected {
  color: #c27b00;
} */
.logout {
  display: flex;
  align-items: center;
}

.p2 {
  padding: 2rem;
}

.profileImage {
  width: 32px;
  height: 32px;
  border-radius: 12px;
}

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loginFormContainer {
  width: 400px;
}

.logout {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.ant-table-wrapper {
  overflow: auto;
}

.ant-table table {
  background-color: white;
}

.ant-anchor-ink::before {
  background-color: rgba(0, 0, 0, 0);
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: rgb(255, 240, 197);
}

.ant-descriptions-item-label {
  font-weight: 500;
}

.ant-form-item {
  margin-bottom: 16px;
}

.purchasingStats .statSpan {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.275rem;
}

.ant-btn-sm {
  font-size: 12px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f7f7f7;
}

.ant-tabs-nav::before {
  border-bottom: none !important;
}

.fixedRightBottom {
  position: fixed;
  right: 62px;
  bottom: 62px;
}

.flexAllCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  background-color: #eee;
  border-radius: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #ddd;
}
.stats {
  display: flex;
  height: 140px;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.stats p {
  font-size: 16px;
  margin-bottom: 0.375rem;
}
.stats img {
  width: 50%;
  object-fit: contain;
  padding: 1rem;
}

.stats .count {
  margin-left: 0.5rem;
  font-size: 14px;
}

.printOnly,
.section-to-hide,
.printOnlyHeader {
  display: none !important;
}

.statsPage .ant-tag {
  padding: 0.475rem 0.85rem;
  background-color: #ebebeb;
  border: none;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
}

.section-to-print-raw {
  background-color: #fff;
  padding: 2rem;
  min-height: 100vh;
  width: 720px;
}
.ant-table-selection-column {
  display: none !important;
}
.A4 p {
  margin-bottom: 0;
}

.tagTab .ant-tag {
  margin-right: 0;
  border-radius: 0;
}

.ant-layout-sider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ant-layout-sider::-webkit-scrollbar {
  width: 0px;
  display: none;
}
.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noScrollBar::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.table .heading {
  display: none;
}

.advanceTable {
  width: 100%;
  text-align: center;
}
.advanceTable thead {
  background: #eaeaea;
}
.advanceTable td,
.advanceTable th {
  border: 1px solid #eaeaea;
}
.advanceTable th,
.advanceTable td {
  padding: 10px 5px;
}

.ant-menu {
  border-right: 0px !important;
}
.ant-menu-submenu .ant-menu-item {
  padding-left: 36px !important;
}
.ant-menu-sub.ant-menu-inline {
  background-color: #f2f2f2;
}

.sideBar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  width: 240px;
  border: none;
  z-index: 1000;
  /* border-right: 2px solid #eee; */
  box-shadow: 5px -1px 15px -9px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 5px -1px 15px -9px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px -1px 15px -9px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
}

.mainContent {
  margin-left: 240px;
  min-height: 100vh;
  background-color: #f5f3f3;
  margin-top: 64px;
  padding: 0.5rem;
  padding-bottom: 5rem;
}

.navProfileInfo {
  margin-left: 0.75rem;
  color: #000;
  display: flex;
  align-items: center;
}

.navProfileInfo div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.navProfileInfo img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.dashboardImages img {
  padding: 1rem;
  width: 100%;
  background-color: #fff;
}
.contentHeader {
  background-color: #fff;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border: none;
  position: fixed;
  top: 0;
  left: 240px;
  right: 0;
  z-index: 999;
  color: #d03d56;
  box-shadow: 1px 7px 15px -9px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 7px 15px -9px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 7px 15px -9px rgba(0, 0, 0, 0.25);
}

.pageLoading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 1100;
}

.bottomFixed {
  position: fixed;
  bottom: 0;
  left: 240px;
  right: 0px;
  margin-bottom: 0 !important;
  z-index: 100;
}

.bottomFixed .ant-btn {
  border-radius: 0px;
  width: 100%;
  height: 50px;
}

/* .content{
  margin-top: 64px;
  padding: 0.5rem;
} */

.bottomBar {
  display: none;
}

.customTable * {
  font-size: 13px;
}

.ant-btn-primary {
  box-shadow: none !important;
}

.customTable {
  width: 100%;
  background-color: #fff;
}

.ant-table-selection-column {
  display: none !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #eaf0f4 !important;
}

.customTable thead th {
  padding: 8px;
  font-weight: 500;
  background: #fafafa;
}

.customTable th p {
  margin: 8px 0px;
  border-right: 1px solid #eee;
  padding-right: 8px;
}

.customTable input {
  text-align: center;
  border: 1px solid #aaa;
  padding: 4px 8px;
  border-radius: 2px;
}

.customTable input::-webkit-input-placeholder {
  color: #eee;
}

.customTable button {
  background-color: #002b44;
  color: #fff;
  cursor: pointer;
}

.customTable tr {
  border-bottom: 1px solid #eee;
}
.customTable td {
  align-items: center;
  text-align: center;
  padding: 16px;
}

@media (max-width: 768px) {
  .sideBar {
    left: -240px;
    box-shadow: none;
  }
  .bottomFixed {
    left: 0px;
    bottom: 64px;
  }
  .mainContent {
    margin-left: 0;
    padding-bottom: 8rem;
  }
  .contentHeader {
    left: 0;
  }
  .bottomBar {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #fff;
    border-top: 1px solid #eee;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 16px;
    z-index: 1100;
  }
}

.noWrap {
  white-space: nowrap !important;
}

.orderSummaryPhone {
  display: none;
}

@media (max-width: 572px) {
  .logo {
    width: 140px;
  }
  .table .heading {
    display: block;
  }
  .orderSummaryPhone {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 1rem;
  }

  .orderSummaryPhone div {
    display: flex;
    justify-content: space-between;
  }

  .orderSummaryPhone .item {
    margin-bottom: 0.5rem;
  }

  .summaryName {
    flex: 1;
    justify-content: flex-start !important;
  }
  .summaryAction {
    width: 40px;
    justify-content: flex-end !important;
  }

  .orderSummaryPhone span {
    text-align: right;
  }

  .hidePhone {
    display: none;
  }
  .table th {
    display: none;
  }

  .ant-table-container {
    border: none !important;
  }

  .table tbody tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 8px solid #e5e5e5;
  }

  .ant-picker-datetime-panel {
    flex-direction: column !important;
  }

  .statusGrid {
    display: grid !important;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr !important;
  }
  nav {
    left: 0 !important;
  }
}

@media print {
  html {
    background-color: white;
  }
  body {
    background-color: white;
  }
  body * {
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
    min-height: auto !important;
    overflow-x: hidden;
  }
  main {
    background-color: white;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
  }
  .section-to-print {
    position: absolute !important;
    margin: auto;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    height: 100%;
    background-size: 80% !important;
    padding: 0.5rem !important;
    width: auto !important;
    height: auto !important;
  }
  .printComp {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .mainContent {
    min-height: auto !important;
  }
  .noPageBreak {
    page-break-after: always !important;
    page-break-before: always !important;
    page-break-inside: avoid !important;
  }
  .printOnly {
    display: flex !important;
    margin-top: 5rem !important;
  }
  .noMarginPadding {
    margin: 0 !important;
    padding: 0 !important;
  }
  .printOnlyHeader {
    display: flex !important;
  }
  .noPrint {
    display: none !important;
  }
  .noPrint * {
    display: none !important;
  }
  .smallFont * {
    font-size: 11px !important;
  }
  .courierHeader {
    font-size: 18px !important;
  }
}
